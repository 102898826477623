import classNames from "classnames";
import { endOfDay, differenceInHours } from "date-fns";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import { useLocation } from "react-router-dom";
import { celciusToFahrenheit } from "../localization";
import AlertsContext from "../state/AlertsContext";
import { DeviceContext } from "../state/DeviceContext";
import { SiteContext } from "../state/SiteContext";
import { DashContext } from "../state/DashContext";
import Card from "./Card";
import ExportButton from "./ExportButton";
import Graph from "./Graph";
import Spinner from "./Spinner";
import { IconCheck } from "./Icons";
import { MdDragHandle } from "react-icons/md";
import { PopupSection } from "./Popup";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RainSensorList from "./RainSensorList";
import ScoutOverview from "./ScoutOverview";
import { useIntl, FormattedMessage } from "react-intl";
import { getStepSize } from "../utility/common";
import {
  getDefiniteMax,
  getDefiniteMin,
  getMaxAlertRule,
  getMinAlertRule,
  getMaxMeasurement,
  getMinMeasurement,
  generateGridLines,
  prepareAggregateLine,
  prepareMultipleLine,
  rulesToLines,
  prepareMultipleBar,
} from "../utility/measurement-helpers";
import { differenceInMinutes, differenceInDays } from "date-fns/esm";
import {
  useWhatChanged
} from '@simbathesailor/use-what-changed';
import Alert from "./Alert";
import { scoutsHaveDevice, groupsHaveDevice, allScoutsHaveOnlyDevice, allGroupsHaveOnlyDevice } from "../utility/device-helpers";
import { debounce } from 'lodash';

export const DashCardsContext = createContext();

const defaultDashPrefs = {
  cards: {
    overview: {},
    temperature: {},
    moisture: {},
    salinity: {},
    water_balance: { visible: false },
    oxygen: {visible: false} 
  },
  order: ["overview", "moisture", "temperature", "salinity", "water_balance", "oxygen"],
};

const getCardUISetting = (user, card, key) => {
  try {
    return user && user["ui_settings"]["cards"][card][key];
  } catch (e) {
    return null;
  }
};

const useToggleButton = (label, initialState, callback) => {
  const [toggled, setToggled] = useState(initialState ? true : false);
  const button = useMemo(
    () => (
      <button
        onClick={() => {
          setToggled((toggled) => {
            callback && callback(toggled);
            return !toggled;
          });
        }}
        className={classNames("flex justify-between", {
          "text-scout-blue font-medium": toggled,
        })}
      >
        <span>{label}</span>
        {toggled && <IconCheck />}
      </button>
    ),
    [toggled, label, callback]
  );
  return [toggled, button];
};

export const ShowElementButton = (element, elementText) => {
  const { updateSettings, uiSettings } = useContext(DashContext);
  const [cardSettings, setCardSettings] = useState(
    uiSettings ? uiSettings : defaultDashPrefs
  );

  const [visible, toggle] = useState(
    cardSettings.cards[element].hasOwnProperty("visible")
      ? cardSettings.cards[element]["visible"]
      : true
  );

  const button = useMemo(
    () => (
      <button
        onClick={() => {
          toggle((visible) => !visible);

          const newCards = cardSettings;
          newCards.cards[element]["visible"] = !visible;

          updateSettings(newCards);
          setCardSettings(newCards);
        }}
        className={classNames("flex justify-between", {
          "text-scout-blue font-medium": !visible,
        })}
      >
        <span>
          {visible ? <FormattedMessage id="card.options.hide_element"/> : <FormattedMessage id="card.options.show_element"/>} {elementText ? elementText : element}
        </span>
      </button>
    ),
    [visible, element, elementText, updateSettings, cardSettings]
  );
  return [visible, button, updateSettings];
};

const useRulesToggle = (label) => {
  const intl = useIntl();
  const { state: locationState } = useLocation();
  const toggleButton = useToggleButton(
    intl.formatMessage({id:"card.options.alert_rules"}),
    locationState?.fromAlerts
  );
  return toggleButton;
};

const TemperatureCard = ({
  currentUser,
  alertRules,
  ExportMenu,
  RainSensorSection,
  measurements,
  tempUnit,
  ...rest
}) => {
  const intl = useIntl();
  const metric = "temperature";
  const [showRules, rulesButton] = useRulesToggle();
  const imperial = tempUnit === "fahrenheit";
  const symbol = imperial ? "°F" : "°C";
  const freezing = imperial ? 32 : 0;
  const [visible, toggleGraphButton] = ShowElementButton(metric, intl.formatMessage({id: "temperature_card.popupsection.temperature"}));

  const maxMeasurement = getMaxMeasurement(measurements, metric);
  const minMeasurement = getMinMeasurement(measurements, metric);
  const maxRule = getMaxAlertRule(alertRules, metric);
  const minRule = getMinAlertRule(alertRules, metric, minMeasurement);

  const stepSize = 5;

  const max =
    showRules && maxRule
      ? getDefiniteMax(maxMeasurement, stepSize, maxRule)
      : getDefiniteMax(maxMeasurement, stepSize);

  const min =
    showRules && minRule
      ? getDefiniteMin(minMeasurement, stepSize, minRule)
      : getDefiniteMin(minMeasurement, stepSize);

  const lines =
    Number.isFinite(max) &&
    Number.isFinite(min) &&
    generateGridLines(min, max, stepSize);

  return (
    <Card
      menu={
        <>
          {alertRules && (
            <PopupSection title={`${intl.formatMessage({
              id: "card.tooltip.label.show",
            })}`}>
              {toggleGraphButton}
              {rulesButton}
            </PopupSection>
          )}
          <RainSensorSection />
          <ExportMenu />
        </>
      }
      heading={<FormattedMessage id="temperature_card.title.temperature" values={{symbol: symbol }}/>}
      span
    >
      {visible && (
        <Graph
          {...(measurements.temperature
            ? {
              dataSeries: Object.values({
                ...measurements.temperature,
                ...measurements.rain,
              }),
            }
            : {})}
          metricType={{
            value: "temperature",
            symbol,
            label: "Temperature",
          }}
          valueFormatterY={(x) => `${x?.toFixed(0)}`}
          valueFormatterTooltip={(x) => `${x?.toFixed(1)} ${symbol}`}
          yAxisConfig={{ stepSize: 5 }}
          yAxisMin={min}
          yAxisMax={max}
          gridConfig={{
            y: {
              lines: lines
                ? lines.concat([
                  {
                    value: freezing,
                    class: "bb-grid-freezing",
                    ...(imperial ? { text: `${freezing} ${symbol}` } : {}),
                    position: "start",
                  },
                  ...(showRules && alertRules
                    ? rulesToLines(alertRules, {
                      lo: (val) =>
                        imperial ? celciusToFahrenheit(val) : val,
                      hi: (val) =>
                        imperial ? celciusToFahrenheit(val) : val,
                    })
                    : lines
                      ? lines
                      : []),
                ])
                : [],
            },
          }}
          {...rest}
        />
      )}
    </Card>
  );
};

const MoistureCard = ({
  currentUser,
  uiSettings,
  updateSettings,
  alertRules,
  ExportMenu,
  RainSensorSection,
  measurements,
  fieldCapacityMax,
  metadata,
  ...rest
}) => {
  const intl = useIntl();
  const metric = "moisture";
  const [showRules, rulesButton] = useRulesToggle();
  const [visible, toggleGraphButton] = ShowElementButton(metric, intl.formatMessage({id: "moisture_card.popupsection.moisture"}));
  const [showFieldCapacity, fieldCapacityButton] = useToggleButton(
    intl.formatMessage({ id: "card.tooltip.label.field_capacity" })
  );
  const [yaxisSettings, setYaxisSettings] = useState(
    uiSettings ? uiSettings : defaultDashPrefs
  );

  const lockYAxisMin = (bool) => {
    const newYaxisSettings = yaxisSettings;

    newYaxisSettings["cards"]["moisture"] = { yaxis_lock: bool };

    updateSettings(newYaxisSettings);
    setYaxisSettings(newYaxisSettings)
  };

  const [isMoistureYAxisUnlocked, lockYAxisButton] = useToggleButton(
    intl.formatMessage({ id: "card.tooltip.label.yaxis_unlock" }),
    getCardUISetting(currentUser, "moisture", "yaxis_lock") === false,
    lockYAxisMin
  );

  const maxMeasurement = getMaxMeasurement(measurements, metric);
  const minMeasurement = getMinMeasurement(measurements, metric);
  const maxRule = getMaxAlertRule(alertRules, metric);
  const minRule = getMinAlertRule(alertRules, metric, minMeasurement);

  const stepSize = 5;

  const max =
    showRules && maxRule && showFieldCapacity
      ? getDefiniteMax(
        maxMeasurement,
        stepSize,
        Math.max(fieldCapacityMax, maxRule)
      )
      : showRules && maxRule
        ? getDefiniteMax(maxMeasurement, stepSize, maxRule)
        : showFieldCapacity && fieldCapacityMax
          ? getDefiniteMax(maxMeasurement, stepSize, fieldCapacityMax)
          : getDefiniteMax(maxMeasurement, stepSize);

  const min = !isMoistureYAxisUnlocked
    ? 0
    : showRules && minRule
      ? getDefiniteMin(minMeasurement, stepSize, minRule)
      : Math.max(getDefiniteMin(minMeasurement, stepSize), 0);

  const lines =
    Number.isFinite(max) &&
    Number.isFinite(min) &&
    generateGridLines(min, max, stepSize);

  const isPending = () => {
    if (metadata instanceof Set) {
      return metadata.has(metric);
    }
    return false;
  }

  return (
    <Card
      menu={
        <>
          <PopupSection
            title={`${intl.formatMessage({
              id: "card.tooltip.label.show",
            })}`}
          >
            {toggleGraphButton}
            {lockYAxisButton}
            {fieldCapacityButton}
            {alertRules && rulesButton}
          </PopupSection>
          <RainSensorSection />
          <ExportMenu />
        </>
      }
      heading={<FormattedMessage id="moisture_card.title.moisture"/>}
      span
    >
      {isPending() && (
        <Alert message={<FormattedMessage id="graph.calculation.pending"/>} infoLevel={"Notice"} />
      )}
      {visible && (
        <Graph
          className="p-2"
          noTitle
          {...(measurements.moisture
            ? {
              dataSeries: Object.values({
                ...measurements.moisture,
                ...measurements.rain,
              }),
            }
            : {})}
          metricType={{
            value: "moisture",
            symbol: "%",
            label: "Moisture",
          }}
          valueFormatterY={(x) => `${x?.toFixed(0)}`}
          valueFormatterTooltip={(x) => `${x?.toFixed(1)} %`}
          yAxisMin={min}
          yAxisMax={max}
          yAxisConfig={{ stepSize: stepSize }}
          gridConfig={{
            y: {
              lines: lines
                ? lines.concat([
                  ...(min < stepSize
                    ? [{ value: 0, class: "bb-grid-zero" }]
                    : []),
                  ...(showRules && alertRules
                    ? rulesToLines(alertRules, {
                      lo: (val) => val * 100,
                      hi: (val) => val * 100,
                    })
                    : lines
                      ? lines
                      : []),
                ])
                : [],
            },
          }}
          fieldCapacity={showFieldCapacity}
          {...rest}
        />
      )}
    </Card>
  );
};

const WaterBalanceGraph = ({
  ExportMenu,
  RainSensorSection,
  measurements,
  selectedDevices,
  metadata,
  ...rest
}) => {
  const intl = useIntl();
  const metric = "water_balance";
  const [visible, toggleGraphButton] = ShowElementButton(
    "water_balance",
    intl.formatMessage({id: "water_balance_card.popupsection.water_balance"})
  );

  const hasSameIrrigationThreshold = (selectedDevices) =>
    selectedDevices.every(
      (element) =>
        element.location.irrigation_threshold ===
        selectedDevices[0].location.irrigation_threshold
    );

  const irrigationThreshold =
    (selectedDevices?.length > 1 &&
      hasSameIrrigationThreshold(selectedDevices)) ||
      selectedDevices?.length === 1
      ? selectedDevices[0].location.irrigation_threshold
      : undefined;

  const maxMeasurement = getMaxMeasurement(measurements, metric);
  const minMeasurement = getMinMeasurement(measurements, metric);

  const stepSize = 0.25;
  const max = irrigationThreshold
    ? getDefiniteMax(maxMeasurement, stepSize, irrigationThreshold)
    : getDefiniteMax(maxMeasurement, stepSize);

  const minWithStepSize = minMeasurement
    ? getDefiniteMin(Math.min(minMeasurement, 0), stepSize)
    : 0;
  const lines =
    Number.isFinite(max) &&
    Number.isFinite(minWithStepSize) &&
    generateGridLines(minWithStepSize, max, stepSize);

  // "redefine" the min value to avoid unnecessary negative lines on the graph
  // as getDefiniteMin would include a lower step if minMeasurement < stepSize
  const min = minMeasurement < 0 ? minWithStepSize : 0;

  const isPending = () => {
    if (metadata instanceof Set) {
      return metadata.has(metric);
    }
    return false;
  }

  return (
    <Card
      menu={
        <>
          <PopupSection title={`${intl.formatMessage({
              id: "card.tooltip.label.show",
            })}`}>{toggleGraphButton}</PopupSection>
          <RainSensorSection />
          <ExportMenu />
        </>
      }
      heading={<FormattedMessage id="water_balance_card.title.water_balance"/>}
      span
    >
      {isPending() && (
        <Alert message={<FormattedMessage id="graph.calculation.pending"/>} infoLevel={"Notice"} />
      )}
      {visible && (
        <Graph
          className="p-2"
          noTitle
          {...(measurements.water_balance
            ? {
              dataSeries: Object.values({
                ...measurements.water_balance,
                ...measurements.rain,
              }),
            }
            : {})}
          metricType={{
            value: "water_balance",
            symbol: "",
            label: "Water Balance",
          }}
          valueFormatterY={(x) => `${x?.toFixed(2)}`}
          valueFormatterTooltip={(x) => `${x?.toFixed(2)}`}
          yAxisMin={min}
          yMaxMin={1}
          yAxisMax={!max ? undefined : max > 1 ? max : 1}
          yAxisConfig={{ stepSize: stepSize }}
          irrigationThreshold={irrigationThreshold}
          regionConfig={
            irrigationThreshold
              ? [
                {
                  axis: "y",
                  start: min,
                  end: 0.25,
                  class: "region-red",
                },
                {
                  axis: "y",
                  start: 0.25,
                  end: irrigationThreshold,
                  class: "region-yellow",
                },
                {
                  axis: "y",
                  start: irrigationThreshold,
                  end: 1,
                  class: "region-green",
                },
              ]
              : undefined
          }
          gridConfig={{
            y: {
              lines: lines
                ? lines.concat([
                  { value: 1.0, class: "field-capacity-one" },
                  { value: 0, class: "bb-grid-zero" },
                ])
                : [],
            },
          }}
          {...rest}
        />
      )}
    </Card>
  );
};

const SalinityCard = ({
  currentUser,
  alertRules,
  ExportMenu,
  RainSensorSection,
  measurements,
  metadata,
  ...rest
}) => {
  const intl = useIntl()

  const [showRules, rulesButton] = useRulesToggle();
  const [showBulk, conductivityButton] = useToggleButton(intl.formatMessage({id:"salinity_card.options.bulk_conductivity"}));
  const [visible, toggleGraphButton] = ShowElementButton("salinity", intl.formatMessage({id: "salinity_card.popupsection.salinity"}));

  let metric = showBulk ? "conductivity" : "salinity";
  const maxMeasurement = getMaxMeasurement(measurements, metric);
  const maxRule = getMaxAlertRule(alertRules, metric);

  const minimumStepSize = 0.25;
  const max =
    showRules && maxRule
      ? getDefiniteMax(maxMeasurement, minimumStepSize, maxRule)
      : getDefiniteMax(maxMeasurement, minimumStepSize);

  const stepSize = getStepSize(minimumStepSize, max);
  const min = 0.1;

  const lines =
    Number.isFinite(max) &&
    Number.isFinite(min) &&
    generateGridLines(min, max, stepSize);

  const isPending = () => {
    if (metadata instanceof Set) {
      return metadata.has('salinity');
    }
    return false;
  }

  // Adjusts the ys values in the scoutData object, ensuring values are greater than or equal to 0.1
  const adjustScoutYs = (scoutData, threshold) => ({
    ...scoutData,
    columns: {
      ...scoutData.columns,
      // Map each value in the ys array, adjusting values except for the first one
      ys: (scoutData.columns?.ys || []).map((value, index) =>
        index !== 0 ? adjustValues(value, threshold) : value
      ),
    },
  });

  // Maps values less than threshold to threshold value
  const adjustValues = (value, threshold) => {
    if (Array.isArray(value)) {
      // If value is an array, adjust the element at index 1
      return [value[0], Math.max(value[1], threshold), value[2]];
    } else {
      // If not an array, simply adjust the value
      return Math.max(value, threshold);
    }
  };

  // Adjusted columns by applying adjustScoutYs to each scoutData in measurements.salinity
  const adjustedColumns = Object.values(measurements.salinity || {}).map((scoutData) =>
    adjustScoutYs(scoutData, 0.1)
  );

  // Formatters for displayed values
  const valueFormatterTooltip = (x) => (x <= 0.1 ? "<0.1" : `${x?.toFixed(2)} dS/m`);
  const valueFormatter = (x) => (x <= 0.1 ? 0.1 : `${x?.toFixed(2)}`)

  return (
    <Card
      menu={
        <>
          <PopupSection title={`${intl.formatMessage({
              id: "card.tooltip.label.show",
            })}`}>
            {toggleGraphButton}
            {alertRules ? rulesButton : null}
            {conductivityButton}
          </PopupSection>
          <RainSensorSection />
          <ExportMenu />
        </>
      }
      heading={showBulk ? intl.formatMessage({id:"salinity_card.title.bulk_conductivity"}) : intl.formatMessage({id:"salinity_card.title.salinity"})}
      span
    >
      {isPending() && (
        <Alert message={<FormattedMessage id="graph.calculation.pending"/>} infoLevel={"Notice"} />
      )}
      {visible && (
        <Graph
          {...(showBulk
            ? measurements.conductivity
              ? {
                dataSeries: Object.values({
                  ...measurements.conductivity,
                  ...measurements.rain,
                }),
              }
              : {}
            : measurements.salinity
              ? {
                dataSeries: Object.values({
                  ...(adjustedColumns ?? measurements.salinity),
                  ...measurements.rain,
                }),
              }
              : {})}
          valueFormatterY={
            showBulk
              ? (x) => `${x?.toFixed(2)}`
              : valueFormatter // For salinity values, format small values as <0.1.
          }
          valueFormatterTooltip={
            showBulk
              ? (x) => `${x?.toFixed(2)} dS/m`
              : valueFormatterTooltip // For salinity values, format small values as <0.1.
          }
          yAxisMax={max}
          yAxisMin={
            showBulk
              ? 0
              : min-0.02 // Avoids points "falling out of bounds".
            } 
          yAxisConfig={{ stepSize: stepSize }}
          metricType={
            showBulk
              ? {
                value: "conductivity",
                symbol: "dS/m",
                label: "Conductivity",
              }
              : {
                value: "salinity",
                symbol: "dS/m",
                label: "Salinity",
              }
          }
          gridConfig={{
            y: {
              lines: showBulk
                ? lines
                  ? lines
                  : []
                : lines
                  ? lines.concat([
                    { value: 0, class: "bb-grid-zero" },
                    ...(showRules && alertRules
                      ? rulesToLines(alertRules)
                      : lines
                        ? lines
                        : []),
                  ])
                  : [],
            },
          }}
          {...rest}
        />
      )}
    </Card>
  );
};

const OxygenCard = ({
  currentUser,
  alertRules,
  ExportMenu,
  measurements,
  metadata,
  ...rest
}) => {
  const intl = useIntl();
  const [visible, toggleGraphButton] = ShowElementButton("oxygen", intl.formatMessage({id: "oxygen_card.popupsection.oxygen"}));


  const stepSize = 5;
  const max = 21;
  const min = 0; 

  const lines =
  Number.isFinite(max) &&
  Number.isFinite(min) &&
  generateGridLines(min, max, stepSize);

  return(
    <Card
      menu={
        <>
          <PopupSection title={`${intl.formatMessage({
              id: "card.tooltip.label.show",
            })}`}>
            {toggleGraphButton}
          </PopupSection>
          <ExportMenu />
        </>
      }
      heading={intl.formatMessage({id:"oxygen_card.title.oxygen"}) + " (%)"}
      span
    >
      {visible && (
        <Graph
          {...(measurements.oxygen
            ? {
              dataSeries: Object.values({
                ...measurements.oxygen
              }),
            }
            : {})}
            metricType={{
              value: "oxygen",
              symbol: "%",
              label: "Oxygen",
            }}
          valueFormatterY={(x) => `${x?.toFixed(0)}`}
          valueFormatterTooltip={(x) => `${x?.toFixed(1)} %`}
          yAxisConfig={{ stepSize: stepSize }}
          yAxisMin={min}
          yAxisMax={max}
          regionConfig={
            [
              {
                axis: "y",
                start: min,
                end: 10,
                class: "region-red",
              },
              {
                axis: "y",
                start: 10,
                end: 12,
                class: "region-orange",
              },
              {
                axis: "y",
                start: 12,
                end: 14,
                class: "region-yellow",
              },
              {
                axis: "y",
                start: 14,
                end: max,
                class: "region-green",
              },
            ]
          }
          gridConfig={{
            y: {
              lines: lines
                ? lines
                : [],
            },
          }}
          {...rest}
        />
      )} 
    </Card>
       
  );
};

const variables = [
  "moisture",
  "temperature",
  "conductivity",
  "salinity",
  "water_balance",
  "oxygen"
];

export default function DashCards({
  scouts,
  groups,
  selectedRainSensors,
  toggleRainSensor,
  timeStart,
  timeEnd,
  aggregateAll,
  zoom,
  datePickerRef,
  refreshData,
  scrollYPosition,
  dashContentRef
}
) {
  const intl = useIntl();
  const {
    getMeasurementsDownsampled,
    getSensorDataSummed,
    rainSensors,
    scouts: scoutlist,
  } = useContext(DeviceContext);
  const { currentUser, uiSettings, updateSettings } = useContext(DashContext);
  const smoothGraph = currentUser.pref_graph_smoothing;
  const tempUnit = currentUser.pref_unit_temp;

  const [measurements, setMeasurements] = useState({});
  const [metadata, setMetadata] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const { rules } = useContext(AlertsContext);
  const { currentSite } = useContext(SiteContext);

  const positionRef = useRef(scrollYPosition);

  const singleScoutSelected = scouts?.length === 1 && groups?.length === 0;
  const singleGroupSelected = groups?.length === 1 && scouts?.length === 0;

  const deviceRules = singleScoutSelected
    ? rules?.filter(({ group_id }) => scouts[0].groups.includes(group_id))
    : singleGroupSelected
      ? rules?.filter(({ group_id }) => groups[0].id === group_id)
      : undefined;

  const selectedDevices = useMemo(() => {
    if (groups && scouts) {
      let allDevices = new Set();
      groups.forEach((group) => {
        scoutlist
          .filter(({ id }) => group.devices.includes(id))
          .forEach(allDevices.add, allDevices);
      });
      scouts.forEach((scout) => allDevices.add(scout));
      return [...allDevices];
    } else {
      return [];
    }
  }, [groups, scouts, scoutlist]);

  const fieldCapacityLine = useCallback(
    (id) => {
      const scout = scoutlist.find((s) => s.name === id);
      return scout
        ? {
          class: `hover-line`,
          position: "end",
          text: `Field Capacity: ${id}`,
          value: scout.location.field_capacity * 100,
        }
        : undefined;
    },
    [scoutlist]
  );

  const irrigationThresholdLine = useCallback(
    (id) => {
      const scout = scoutlist.find((s) => s.name === id);
      return scout
        ? {
          class: `hover-line`,
          position: "end",
          text: `${intl.formatMessage({id: "water_balance_graph.label.irrigation_threshold"})}: ${id}`,
          value: scout.location.irrigation_threshold,
        }
        : undefined;
    },
    [scoutlist, intl]
  );

  const fieldCapacityMax =
    selectedDevices.length > 0
      ? selectedDevices.reduce(
        (min, scout) =>
          scout.location.field_capacity >= min
            ? scout.location.field_capacity
            : min,
        0
      )
      : undefined;

  const windowSize = 500;

  useWhatChanged([currentSite.name,
    scoutlist,
    selectedDevices,
    timeStart,
    timeEnd,
    scouts,
    groups,
    getMeasurementsDownsampled,
    aggregateAll,
    tempUnit,
    windowSize,
    smoothGraph,
    datePickerRef,
    getSensorDataSummed,
    selectedRainSensors])

    useEffect(() => {
          positionRef.current = scrollYPosition; // cache current scroll position
    });
 
  useEffect(() => {
    // Use lodash debounce to prevent multiple calls to fetchData when user selects multiple devices in quick succession
    const fetchData = debounce(() => {
      setMeasurements({});

      var prevPosition = positionRef.current;

      if (selectedDevices.length !== 0 && timeStart && timeEnd && windowSize) {
        document.body.style.cursor = "wait";
        if (datePickerRef.current) {
          datePickerRef.current.setLoading(true);
        }
        let canceled = false;
        let promises = [];
        const timeEndParam = differenceInHours(timeEnd, timeStart) < 72 ? timeEnd : endOfDay(timeEnd);
        const qs = {
          since: timeStart.toISOString(),
          until: timeEndParam.toISOString(),
          // If we have a lot of devices, we need to decrease the amount of points drawn
          window_size: Math.floor(windowSize / selectedDevices.length),
        };
        // This corresponds to the "Entire site" view
        if (aggregateAll) {
          promises.push(
            getMeasurementsDownsampled(selectedDevices, {
              since: timeStart.toISOString(),
              until: timeEndParam.toISOString(),
              fields: "timestamp," + variables.join(","),
              aggregate_all: true,
              window_size: windowSize,
            }, "v2")
              .then((data) =>
                  prepareAggregateLine(data.data, currentSite.name, tempUnit, variables, data.timestamp, data.metadata)
              )
          );
        } else {
          // We want to always show scouts without aggregation
          if (scouts.length > 0) {
            promises.push(
              getMeasurementsDownsampled(scouts, qs, "v2")
                .then((data) =>
                      prepareMultipleLine(data.data, scouts, tempUnit, variables, data.timestamp, data.metadata)
                )
            );
          }
          // If we only have one group selected we should just treat it as a quick way
          // to display the scouts belonging to it
          if (groups.length === 1 && scouts.length === 0) {
            const groupDevs = groups[0].devices;
            const extraDevices = scoutlist.filter(
              (sc) =>
                groupDevs.includes(sc.id) &&
                !scouts.some((scc) => scc.id === sc.id)
            );
            promises.push(
              getMeasurementsDownsampled(extraDevices, qs, "v2")
                .then((data) =>
                      prepareMultipleLine(data.data, extraDevices, tempUnit, variables, data.timestamp, data.metadata)
                )
            );
            // If we have more than one group we need to condense the information to avoid
            // making the graph too busy
          } else if (
            groups.length > 1 ||
            (groups.length === 1 && scouts.length > 0)
          ) {
            groups.forEach((group) => {
              if (group.devices.length > 0) {
                promises.push(
                  getMeasurementsDownsampled(
                    group.devices.map((val) => ({ id: val })),
                    { ...qs, aggregate_all: true },
                    "v2"
                  )
                    .then((data) =>
                          prepareAggregateLine(data.data, group.name, tempUnit, variables, data.timestamp, data.metadata)
                    )
                );
              }
            });
          }
        }
  
        // Rain sensors
        if (selectedRainSensors?.length > 0) {
          // Calculates the window_length by dividing delta with the number of bars to display
          const delta = differenceInMinutes(timeEndParam, timeStart);
          const window_length = Math.floor(delta / 30);
          const metric = "rain";
  
          promises.push(
            getSensorDataSummed(selectedRainSensors, {
              since: timeStart.toISOString(),
              until: timeEndParam.toISOString(),
              window_length,
            }).then((data) =>
              prepareMultipleBar(data, selectedRainSensors, [metric], metric)
            )
          );
        }
  
        const variablesWithConditionalRain =
          selectedRainSensors?.length > 0 ? [...variables, "rain"] : variables;
  
        setLoadingData(true);
        setMetadata();
  
        Promise.all(promises)
          .then((data) => {
            let result = Object.assign(
              {},
              ...variablesWithConditionalRain.map((varr) => ({ [varr]: {} }))
            );
            data.forEach((datum) => {
              if (datum === null) return;
              variablesWithConditionalRain.forEach((varr) => {
                Object.assign(result[varr], datum[varr]);
              });
            });
            if (!canceled) {
              result["timestamp"] = data[0].timestamp
              setMeasurements(result);
            }
            if (data.some(e => e.pending && e.pending.length > 0)) {
              const values = data.filter(e => e.pending && e.pending.length > 0).map(e => e.pending).flat();
              const set = new Set(values);
              setMetadata(set);
            }
            setLoadingFailed(false);
          }).catch(() => {
            setLoadingFailed(true);
          })
          .finally(() => {
            datePickerRef.current.setLoading(false);
            document.body.style.cursor = "default";
            setLoadingData(false);
            
            if(dashContentRef.current){
              // Scroll to the previous position
              dashContentRef.current.scrollTo(0, prevPosition);
            }
            
          });
        return () => {
          canceled = true;
          document.body.style.cursor = "default";
        };
      }
    },1500);

    fetchData();

    return () => {
      fetchData.cancel();
    };
    
  }, [
    currentSite.name,
    scoutlist,
    selectedDevices,
    timeStart,
    timeEnd,
    scouts,
    groups,
    getMeasurementsDownsampled,
    aggregateAll,
    tempUnit,
    windowSize,
    smoothGraph,
    datePickerRef,
    getSensorDataSummed,
    selectedRainSensors,
    dashContentRef
  ]);

  const rulesForMetric = useCallback(
    (metric) =>
      deviceRules
        ? deviceRules.map((rule) => ({
          ...rule,
          condition: rule.conditions.find(
            ({ measurement_type }) =>
              measurement_type.toLowerCase() === metric
          ),
        }))
        : [],
    [deviceRules]
  );

  const ExportMenu = useCallback(
    () => (
      <PopupSection title={`${intl.formatMessage({
        id: "card.tooltip.label.export",
      })}`}>
        {[
          ExportButton({
            scouts: selectedDevices,
            tStart: timeStart,
            tEnd: differenceInHours(timeEnd, timeStart) < 72 ? timeEnd : endOfDay(timeEnd),
            key: "export-csv",
          }),
        ]}
      </PopupSection>
    ),
    [selectedDevices, timeStart, timeEnd, intl]
  );

  const RainSensorSection = useCallback(
    () => (
      <PopupSection
        title={`${intl.formatMessage({
          id: "card.tooltip.label.rain_sensors",
        })}`}
      >
        <RainSensorList
          selectedSensors={selectedRainSensors}
          sensors={rainSensors}
          onSelect={toggleRainSensor}
        />
      </PopupSection>
    ),
    [intl, rainSensors, selectedRainSensors, toggleRainSensor]
  );

  const dashboardData = useMemo(() => {
    const commonProps = {
      currentUser,
      updateSettings,
      measurements,
      ExportMenu,
      RainSensorSection,
      rainSensors,
      selectedRainSensors,
      toggleRainSensor,
      doZoom: zoom,
      domain: [timeStart, timeEnd],
      metadata,
    };

    return {
      cards: {
        overview: {
          id: "overview",
          component: singleScoutSelected ? (
            <ScoutOverview scout={scouts[0]} key={0} />
          ) : (
            {}
          ),
        },
        moisture: {
          id: "moisture",
          component: (
            <MoistureCard
              key={1}
              alertRules={rulesForMetric("moisture")}
              hoverLine={fieldCapacityLine}
              fieldCapacityMax={fieldCapacityMax * 100}
              {...commonProps}
            />
          ),
        },
        temperature: {
          id: "temperature",
          component: (
            <TemperatureCard
              key={2}
              alertRules={rulesForMetric("temperature")}
              tempUnit={tempUnit}
              {...commonProps}
            />
          ),
        },
        salinity: {
          id: "salinity",
          component: (
            <SalinityCard
              key={3}
              alertRules={rulesForMetric("salinity")}
              {...commonProps}
            />
          ),
        },
        water_balance: {
          id: "water_balance",
          component: (
            <WaterBalanceGraph
              key={4}
              hoverLine={irrigationThresholdLine}
              selectedDevices={selectedDevices}
              {...commonProps}
            />
          ),
        },
        oxygen: {
          id: "oxygen", 
          component: (
            <OxygenCard
              key={5}
              {...commonProps}
            />
          )
        },
      },
    };
  }, [
    rulesForMetric,
    tempUnit,
    scouts,
    singleScoutSelected,
    fieldCapacityLine,
    fieldCapacityMax,
    irrigationThresholdLine,
    selectedDevices,
    ExportMenu,
    currentUser,
    measurements,
    timeEnd,
    timeStart,
    zoom,
    updateSettings,
    rainSensors,
    selectedRainSensors,
    toggleRainSensor,
    RainSensorSection,
    metadata
  ]);

  const setInfoMessage = () => {
    console.log(measurements)
    if (measurements.timestamp){
      const timeframe = differenceInDays(timeEnd, timeStart)
      const firstDataDate = new Date(measurements.timestamp.min)
      const diff = differenceInDays(firstDataDate, timeStart)
      if (diff >= (timeframe / 2) && timeframe > 31) {
        return <Alert message={<FormattedMessage id="graph.timeframe.long"/>} infoLevel={"Notice"} />
      }     
    }
  }


  const [orderSettings, setOrderSettings] = useState(
    uiSettings ? uiSettings : defaultDashPrefs
  );

  
  const addMissingGraphs = (orderSettings, setOrderSettings ) => {
    if(!orderSettings?.cards?.oxygen)
    {
      orderSettings.cards["oxygen"] = {visible: false};
      orderSettings.order.push("oxygen");

      

      updateSettings(orderSettings);
      setOrderSettings(orderSettings);
    }
  }

  //Check if user's uiSettings is missing new graphs and add missing graphs
  if(uiSettings)
    addMissingGraphs(orderSettings, setOrderSettings)

  const onDragEnd = useCallback(
    (result) => {
      const { destination, source, draggableId } = result;

      if (!destination) {
        return;
      }

      if (destination.index === source.index) {
        return;
      }

      const dbStorageItem = orderSettings;

      // remove element from the list
      const newOrder = dbStorageItem.order;
      newOrder.splice(source.index, 1);

      // add element to the list at destination.index
      newOrder.splice(destination.index, 0, draggableId);
      dbStorageItem.order = newOrder;

      updateSettings(dbStorageItem);
      setOrderSettings(dbStorageItem);
    },
    [updateSettings, orderSettings]
  );

  const showCard = (card) => {
    
    if (card.id === "oxygen") {
      const groupsHaveOxygenDevice = groupsHaveDevice(groups, "209");
      const scoutsHaveOxygenDevice = scoutsHaveDevice(scouts, "209");
  
      // Hide oxygen card if there are no oxygen devices selected.
      if (!groupsHaveOxygenDevice && !scoutsHaveOxygenDevice) {
        return false;
      }
    }
  
    if (card.id === "salinity") {
      const hasSalinityDevices = groupsHaveDevice(groups, "107") || scoutsHaveDevice(scouts, "107");
      const hasOnlyOxygenDevices = allGroupsHaveOnlyDevice(groups, "209") && allScoutsHaveOnlyDevice(scouts, "209");
  
      // Hide salinity card if selected group does not have 107 protocol sensor or scouts are only oxygen sensors
      if (!hasSalinityDevices || hasOnlyOxygenDevices) {
        return false;
      }
    }
  
    return true;
  }

  return (
    <>
      {!loadingData && !loadingFailed && (
        <>
        {setInfoMessage()}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="cards">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="w-full h-full"
              >
                {orderSettings?.order?.map(
                  (element, idx) =>
                    Object.keys(dashboardData.cards[element].component).length >
                    0 && (
                      showCard(dashboardData.cards[element]) &&
                      (
                        <Draggable
                          key={dashboardData.cards[element].id}
                          draggableId={dashboardData.cards[element].id}
                          index={idx}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              className="relative mb-6"
                            >
                              {dashboardData.cards[element].component}

                              <div
                                {...provided.dragHandleProps}
                                className="absolute left-0 top-0 w-6 h-6 ml-3 mt-3 text-scout-gray-dark"
                              >
                                <MdDragHandle size={24} />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        </>
      )}
      {loadingData && (
        <div style={{ margin: 'auto' }}>
          <Spinner size={72} />
        </div>
      )}
      {loadingFailed && !loadingData && (
        <>
          <div className="flex flex-col justify-center items-center">
            <h1>
              <FormattedMessage id="action.getting_data_failed_heading" />
            </h1>
            <p className="mb-3">
              <FormattedMessage id="action.getting_data_failed" />
            </p>
            <button className="btn bg-white shadow-indigo mr-2" onClick={refreshData} >
              <FormattedMessage id="button.try-again" />
            </button>
          </div>
        </>
      )}
    </>
  );
}
