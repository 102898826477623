import React, { useContext, useState, useEffect, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import SubmitButton from "./SubmitButton"; 
import Card from "./Card";
import {
  Fieldset,
  Input,
  Checkbox,
  NumberInput
} from "./FormWidgets";
import useModal from "../hooks/useModal";
import { buildRequest } from "../api";
import { AuthContext } from "../state/AuthContext";
import { footToMeter } from "../localization";
import { toast } from "react-toastify";
import { useInterval } from "../hooks/useInterval";


const UPDATE_INTERVAL = 20000;


const OxygenCalibration = ({ device, isImperial }) => {
  
  const intl = useIntl(); 

  const [Modal, toggleModal] = useModal();
  const { authenticatedFetch } = useContext(AuthContext);
  
  const [toggled, setToggled] = useState(false);
  const [toggledContinue, setToggleContinue] = useState(false);
  const [toggleDraft, setToggleDraft] = useState(false);
  const [altitude, setAltitude] = useState(0);
  const [refreshedDevice, setRefreshedDevice] = useState(device);

  const refreshDevice = useCallback(
    () => {
      authenticatedFetch(
        buildRequest("GET")
          .withPath(`/devices/${refreshedDevice.id}/`)
      ).then((devices) => {
        if (devices) { setRefreshedDevice(devices) }
      })
    },
    [refreshedDevice, authenticatedFetch]
  );

  useEffect(() => {
    setRefreshedDevice(device);
  }, [device]);

  useInterval(async () => {
    refreshDevice();
  }, UPDATE_INTERVAL);
  

  const isLessThanOneHourAgo = (dateString) => {
    if (dateString === null || dateString === undefined) return null
    const givenDate = new Date(dateString);
    return (new Date() - givenDate) / (1000 * 60 * 60) < 1;
  };

  const handleToggleModal = (prompt = false) => {
  
    const askConfirmation = prompt;
  
    if (askConfirmation) {
      const userConfirmed = window.confirm(
        intl.formatMessage({ id: "form.calibration.modal.cancel_disclaimer" })
      );
  
      if (userConfirmed) {
        setToggled(false);
        setToggleContinue(false);
        setToggleDraft(false);
        toggleModal();
      }
    } else {
      setToggled(false);
      setToggleContinue(false);
      setToggleDraft(false);
      toggleModal();
    }
  };

  const handleAltitudeChange = (event) => setAltitude(event.target.value);

  const isInvalidAltitude = (alt) => alt === "" || isNaN(alt);

  //Calculate what the oxygen percentage should be based on the altitude
  const calculateAirValue = (altitudeMeters) => {
    return isNaN(altitudeMeters)
      ? null
      : (20.9 - 0.002165 * altitudeMeters);
  };

  //Calculate the new calibration value based on the oxygen raw value and the altitude
  const calculateCalibrationValue = (oxygenRaw, altitudeMeters) => {
    const oxygenRawFloat = parseFloat(oxygenRaw);
    return isNaN(oxygenRawFloat) || isNaN(altitudeMeters)
      ? null
      : (oxygenRawFloat * 20.9) / (209 - 0.02165 * altitudeMeters);
  };

  const lastMeasurements = refreshedDevice?.last_measurement || null;
  const lastestOxygenMeasurement = refreshedDevice?.last_measurement?.oxygen || null;
  const altitudeMeters = isImperial ? parseFloat(footToMeter(altitude)) : parseFloat(altitude);
  const airValue = calculateAirValue(altitudeMeters)?.toFixed(1) || null;
  const readingValue = parseFloat(lastestOxygenMeasurement * 100).toFixed(1);

  const updateDeviceCalibration = async (deviceId, calValOut) => {

    if (!lastMeasurements) {
      toast.error(intl.formatMessage({ id: "form.calibration.modal.error" }));
      return;
    }

    const path = `/devices/${deviceId}/`;
    try {
       await authenticatedFetch(
        buildRequest("PATCH")
          .withPath(path)
          .withBody({
            oxygen_current_calib: calValOut,
            oxygen_last_calib: new Date().toISOString()
          })
      );
  
      console.log("Device calibration updated successfully");
      toast.success(intl.formatMessage({ id: "form.calibration.modal.succesful" }));
    } catch (error) {
      console.error("Failed to update device calibration:", error);
      toast.error(intl.formatMessage({ id: "form.calibration.modal.error" }));
    }
  };

  const handleCalibration = () => {
    if (altitudeMeters !== null) {
      const CalVal_out = calculateCalibrationValue(refreshedDevice?.last_measurement?.oxygen_raw, altitudeMeters );
      const calibratedValue = parseFloat(CalVal_out / 100).toFixed(3);

      // Update calibrated values regardless if we update the Device.
      updateDeviceCalibration(refreshedDevice.id, calibratedValue); 

      handleToggleModal();
    } else {
      handleToggleModal();
      toast.error(<FormattedMessage id="form.calibration.modal.error" />);
    }
  };

  const showDisclaimer = () => {
    const renderCalibrationButton = () => (
      <SubmitButton
        disabled={toggleDraft}
        type="button"
        className="w-40 btn btn-orange w-96 float-right"
        onClick={() => setToggleDraft(true)}
      >
        <FormattedMessage id="oxygen.calibrate" />
      </SubmitButton>
    );
    
    if (!isLessThanOneHourAgo(lastMeasurements?.timestamp)) {
      return (
        <div className="mb-6">
          <FormattedMessage id="form.calibration.modal.disclaimer.disconnected" />
        </div>
      );
    }

    const getDisclaimerMessage = () => {

      if((readingValue - airValue) > 1) {
        return <FormattedMessage id="form.calibration.modal.disclaimer.oxygen_high" />;
      }
      else if ((airValue - readingValue)  > 4) {
        return <FormattedMessage id="form.calibration.modal.disclaimer.oxygen_low" />;
      }
      else if ((airValue - readingValue)  > 1) {
        return <FormattedMessage id="form.calibration.modal.disclaimer.degraded" />;
      } 
      else {
        return <FormattedMessage id="form.calibration.modal.disclaimer.no_calibration" />;
      }
    };

    return (
      <>
        <div className="mb-6">
          <FormattedMessage id="form.calibration.modal.dialog" values={{ airValue, readingValue }} />
        </div>
        <div className="mb-6 font-bold">
          {getDisclaimerMessage()}
        </div>
        {Math.abs(airValue - readingValue) > 1 && renderCalibrationButton()}
      </>
    );
  };

  return (
    <Fieldset legendId="form.legend.o2" inline>
      <div className="flex space-x-2 items-center justify-between">
          <div className="w-max">
            <FormattedMessage id="form.calibrated_at" />
          </div>
          <div className="w-min">
            <Input
              type="text"
              value={
                refreshedDevice?.oxygen_last_calib
                  ? new Date(refreshedDevice.oxygen_last_calib).toLocaleDateString()
                  : intl.formatMessage({ id: "form.calibration.modal.never_calibrated" })
              }
              disabled
              className="text-center bg-white bx-1 border-gray-300 rounded"
            />
          </div>
          <SubmitButton type="button" className="btn btn-orange w-30 float-right" onClick={() => handleToggleModal()}>
            <FormattedMessage id="oxygen.calibrate" />
          </SubmitButton>

        <Modal>
          <Card className="py-6 px-6" title={<FormattedMessage id="form.calibration.modal.heading" />}>
            <div className="mb-6">
              <FormattedMessage id="form.calibration.modal.disclaimer" />
            </div>
            <div className="mb-6">
              <Checkbox
              className="mr-2"
              defaultChecked={toggled}
              onClick={() => setToggled((prev) => !prev)}
              />
              <FormattedMessage id="form.calibration.modal.open_air_confirmation" />
            </div>

            {toggled && (
              <div className="mb-6 flex items-center justify-between space-x-2">
                {isImperial ? (
                  <FormattedMessage id="form.calibration.modal.altitude_promt.feet" />
                ) : (
                  <FormattedMessage id="form.calibration.modal.altitude_promt.meter" />
                )}
                <NumberInput value={altitude} onChange={handleAltitudeChange} disabled={toggledContinue} />
                <SubmitButton
                  type="button"
                  className="w-40 btn btn-green h-10"
                  onClick={() => setToggleContinue(true)}
                  disabled={isInvalidAltitude(altitude) || toggledContinue}
                >
                  <FormattedMessage id="form.calibration.modal.continue" />
                </SubmitButton>
              </div>
            )}

            {toggled && toggledContinue && !toggleDraft && showDisclaimer()}

            {toggled && toggledContinue && toggleDraft && (
              <div className="mb-6">
                <FormattedMessage id="form.calibration.modal.disclaimer.confirmation" values={{ airValue }} />
              </div>
            )}

            <div className="flex items-center justify-between space-x-2">
              <SubmitButton type="button" className="w-40 btn btn-red" onClick={() => handleToggleModal(true)}>
                <FormattedMessage id="button.cancel" />
              </SubmitButton>

              {toggleDraft && (
                <SubmitButton type="button" className="w-40 btn btn-green" onClick={handleCalibration}>
                  <FormattedMessage id="button.save" />
                </SubmitButton>
              )}
            </div>
          </Card>
        </Modal>
      </div>
    </Fieldset>
  );
};

export default OxygenCalibration;
