import bb, { bar, line, selection, zoom } from "billboard.js";
import "billboard.js/dist/theme/insight.css";
import chroma from "chroma-js";
import * as d3 from "d3-array";
import { differenceInDays, isSameWeek, isSameYear, differenceInHours } from "date-fns";
import * as deepEqual from "fast-deep-equal";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import config from "../tailwindConfig";
import {
  filterTimeseries,
  hasYAxisType,
  hasY2,
  rainFormatterTooltip,
} from "../utility/graph-helpers";

export default function Graph({
  dataSeries,
  valueFormatterY = (x) => x,
  valueFormatterTooltip = (x) => x,
  doZoom,
  gridConfig,
  regionConfig,
  hoverLine = () => null,
  fieldCapacity,
  irrigationThreshold,
  yMaxMin,
  metricType,
  yAxisMin,
  yAxisMax,
  yAxisConfig,
  domain,
}) {
  const bbRef = useRef();

  const graphRef = useRef();
  const intl = useIntl();
  const haveMedium = useMediaQuery({
    query: `(min-width: ${config.theme.screens.md})`,
  });
  const haveExtraLarge = useMediaQuery({
    query: `(min-width: ${config.theme.screens.xl})`,
  });

  // Init line and bar graphs
  line();
  bar();

  const showY2 = hasY2(dataSeries);

  let yMax = undefined;
  let yMin = undefined;
  yAxisMax ? (yMax = yAxisMax) : yMaxMin && (yMax = yMaxMin);

  if (dataSeries) {
    yMin = d3.min(dataSeries.map(({ yMin }) => yMin));
    if (yAxisMax) {
      yMax = yAxisMax;
    } else {
      yMax = d3.max(dataSeries.map(({ yMax }) => yMax));
      if (irrigationThreshold) {
        yMax = Math.max(yMax, irrigationThreshold);
      }
    }
  }

  const makeTooltip = useCallback(
    function (ttdata) {
      const fDateTime = (x) => `${intl.formatDate(x)} ${intl.formatTime(x)}`;

      let subTitles = ttdata.map((d) => {
        const entry = dataSeries.find((entry) => entry.name === d.name);
        const isRain = entry.metric === "rain";
        const entryTimeseries = entry?.columns?.xs?.slice(1);

        const getTooltipValue = (d) => {
          if(d || d === 0) {
            return valueFormatterTooltip(d);
          } else {
            return "-";
          }
        }

        return Array.isArray(d.value)
          ? [
              `${intl.formatMessage({ id: "graph.tooltip.time" })} ${fDateTime(d.x)}`,
              `${intl.formatMessage({ id: "graph.tooltip.min" })} ${
                getTooltipValue(d.value[2])
              } ${intl.formatMessage({ id: "graph.tooltip.max" })} ${getTooltipValue(d.value[0])}`,
            ]
          : isRain
          ? [
              Array.isArray(entryTimeseries)
                ? `${fDateTime(entryTimeseries[d.index][0])}` +
                  " - " +
                  `${fDateTime(entryTimeseries[d.index][2])}`
                : `t: ${fDateTime(d.x)}`,
            ]
          : [`t: ${fDateTime(d.x)}`];
      });

      const result = (
        <>
          {ttdata.map((d, idx) => {
            const series = dataSeries.find((entry) => entry.name === d.name);
            const isRain = series.metric === "rain";

            return (
              <div
                key={idx}
                style={{
                  opacity: "95%",
                  backgroundColor: chroma(this.color(d.id)).brighten(2),
                  color: chroma(this.color(d.id)).darken(2),
                }}
                className="rounded overflow-hidden shadow-lg px-5 py-2 flex flex-col items-center justify-evenly font-semibold"
              >
                <span className="text-sm">{d.id}</span>
                <p className="inline">
                  {Array.isArray(d.value) && (
                    <span className="text-sm">{intl.formatMessage({ id: "graph.tooltip.mean" })}</span>
                  )}
                  <span className="text-lg ">
                    {isRain
                      ? rainFormatterTooltip(
                          !Array.isArray(d.value) ? d.value : d.value[1]
                        )
                      : valueFormatterTooltip(
                          !Array.isArray(d.value) ? d.value : d.value[1]
                        )}
                  </span>
                </p>
                {subTitles[idx].map((val, idx) => (
                  <p key={idx} className="text-sm">
                    {val}
                  </p>
                ))}
              </div>
            );
          })}
        </>
      );
      return ReactDOMServer.renderToStaticMarkup(result);
    },
    [valueFormatterTooltip, intl, dataSeries]
  );

  const opts = useMemo(
    () => ({
      transition: { duration: 100 },
      padding: {
        top: 10,
        bottom: 10,
      },
      onafterinit: function () {
        this.config("axis.y.show", false);
        this.config("axis.x.show", false);
        this.config("grid.y.show", false);
      },
      data: {
        // This avoids an exception thrown on click of a datapoint
        selection: {
          enabled: selection(),
        },
        type: bar(),
        //Data is added later by using useLayoutEffect hook.
        columns: [],
        empty: {
          label: {
            text: intl.formatMessage({ id: "graph.data.no_data" }),
          },
        },
        onover: function (d) {
          this.focus([d.id]);
        },
        onout: function (d) {
          this.focus();
        }
      },
      axis: {
        x: {
          tick: {
            fit: false,
            culling: {
              max: haveMedium ? 8 : 4,
            },
            format: function (x) {
              const [min, max] = this.internal.org.xDomain;
              const date = intl.formatDate(x, {
                month: "numeric",
                day: "numeric",
              });
              const time = intl.formatTime(x);
              if (differenceInDays(max, min) <= 1) {
                return time;
              } else if (isSameWeek(max, min)) {
                return `${date} ${time}`;
              } else if (isSameYear(max, min)) {
                return intl.formatDate(x, {
                  month: "numeric",
                  day: "numeric",
                });
              } else {
                return intl.formatDate(x, {
                  month: "numeric",
                  year: "numeric",
                  day: "numeric",
                });
              }
            },
          },
          type: "timeseries",
        },
        y2: {
          show: showY2,
          type: "indexed",
        },
        y: {
          tick: {
            ...(yAxisConfig ? yAxisConfig : { count: 7 }),
            format: valueFormatterY,
          },
          ...(yAxisMin !== undefined ? { min: yAxisMin } : {}),
          ...(yAxisMax !== undefined ? { max: yAxisMax } : { max: yMax }),
          padding: {
            bottom: 0,
          },
        },
      },
      bar: {
        width: haveExtraLarge ? 20 : 10,
      },
      spline: {
        interpolation: {
          type: "catmull-rom",
        },
      },
      zoom: {
        enabled: haveMedium ? zoom() : false,
        type: "drag",
        onzoomend: function (newDomain) {
          if (doZoom) {
            setTimeout(() => doZoom(newDomain), 250);
          }
        },
        resetButton: doZoom ? false : true,
      },
      tooltip: haveMedium
        ? {
            contents: makeTooltip,
          }
        : {
            show: false,
          },
      point: {
        r: 0,
        ...(haveMedium
          ? {
              focus: {
                expand: {
                  r: 5,
                },
              },
            }
          : {}),
      },
      grid: {
        lines: {
          front: false,
        },
        ...(yAxisConfig ? {} : { y: { ticks: 7, show: true } }),
      },
      ...(regionConfig ? { regions: regionConfig } : {}),
      line: {
        connectNull: true,
        zerobased: false,
      },
    }),
    [
      valueFormatterY,
      doZoom,
      intl,
      makeTooltip,
      haveMedium,
      haveExtraLarge,
      yAxisMin,
      yAxisMax,
      yMax,
      yAxisConfig,
      regionConfig,
      showY2,
    ]
  );

  const prevSeriesSummary = useRef();

  const dataSeriesSummary = Object.assign(
    {},
    ...(dataSeries && gridConfig
      ? gridConfig?.y?.lines
          .map((line) => ({
            [`line-${line.text ?? "noname"}`]: line.value,
          }))
          .concat([domain])
          .concat(
            dataSeries.map(({ name, columns: { xs } }) => {
              let end = xs.slice(-1)[0];
              let start = xs[1];

              if (Array.isArray(end)) {
                end = end[1];
              }
              if (Array.isArray(start)) {
                start = start[1];
              }
              return {
                [name]: `${xs.length} - ${
                  xs.length > 1 ? end.getTime() - start.getTime() : "none"
                }`,
              };
            })
          )
      : [])
  );

  const prevGridConfig = useRef();

  const bindHoverLine = useCallback(
    (chart, shouldBind) => {
      chart.config("data.onover", (d) => {
        chart.focus([d.id]);
        if (shouldBind || metricType.value === "water_balance") {
          const line = hoverLine(d.id);
          line && chart.ygrids.add(line);
        }
      });
      chart.config("legend.item.onover", (d) => {
        chart.focus([d]);
        if (shouldBind || metricType.value === "water_balance") {
          const line = hoverLine(d);
          line && chart.ygrids.add(line);
        }
      });
      chart.config("data.onout", (d) => {
        chart.focus();
        chart.ygrids.remove({ class: "hover-line" });
      });
      chart.config("legend.item.onout", (d) => {
        chart.focus();
        chart.ygrids.remove({ class: "hover-line" });
      });
    },
    [hoverLine, metricType.value]
  );

  //Calculate dotted regions for line graphs.
  const calculateRegions = (series) => {
    const regions = [];

    const firstDate = new Date(series.columns.xs[1]);
    const lastDate = new Date(series.columns.xs[series.columns.xs.length - 1]);
    const timeScale = differenceInHours(lastDate, firstDate);
    const pointCount = series.columns.xs.length - 1;
    const timeSpacing = timeScale / pointCount;
    const gapSize = (timeSpacing / 24) > 1 ? 24 : 12;

    for (let i = 1; i < series.columns.xs.length; i++) {
      
      const prevDate = new Date(series.columns.xs[i - 1]);
      const currDate = new Date(series.columns.xs[i]);

      const difference = differenceInHours(currDate, prevDate);
      
      if(difference > (timeSpacing * 3)  && difference > gapSize)
      {
        //Add dotted region.
        regions.push({
          start: prevDate,
          end: currDate,
          style: {
            dasharray: "5 2", //Dotted line.
          },
        });
      }
    }

    return regions;
  };

  //Update graph when data changes.
  useLayoutEffect(() => {
    if (
      !graphRef.current ||
      !deepEqual(prevSeriesSummary.current, dataSeriesSummary)
    ) {
      graphRef.current = bb.generate({
        ...opts,
        bindto: bbRef.current,
      });
    }

    const chart = graphRef.current;

    if (regionConfig && dataSeries) {
      const chartRegions = chart.regions();
      if (chartRegions.length < 1) {
        chart.regions(regionConfig);
      }
    } else {
      chart.regions.remove();
    }

    const updateMinMax = () => {
      let newYMax = yMax;

      if (yAxisMin !== undefined) {
        chart.axis.min({ y: yAxisMin });
      }

      if (yMaxMin > newYMax) {
        chart.axis.max({ y: yMaxMin });
      } else {
        chart.axis.max({ y: newYMax });

        if (gridConfig?.y?.lines?.length > 0) {
          let addableLines = [];
          let removableLines = [];
          let newLines = gridConfig.y.lines;
          let prevLines = chart.ygrids();

          newLines.forEach((element) => {
            if (
              prevLines.find(({ value }) => element.value === value) ===
              undefined
            ) {
              addableLines.push(element);
            }
          });

          prevLines?.forEach((element) => {
            if (
              newLines.find(({ value }) => element.value === value) ===
              undefined
            ) {
              removableLines.push(element);
            }
          });

          chart.ygrids.add(addableLines);
          chart.ygrids.remove(removableLines);
        } else {
          chart.ygrids.remove();
        }
      }
      if (yAxisConfig.stepSize) {
        chart.config("axis.y.tick.stepSize", yAxisConfig.stepSize);
      }
    };

    bindHoverLine(chart, fieldCapacity);

    const unloadChart = () => {
      chart.unload({
        done: () => {
          chart.config("axis.x.show", false);
          chart.regions.remove();
          chart.ygrids.remove();
          chart.flush(true);
        },
      });
    };

    const updateYAxes = (data) => {
      chart.config("axis.y2.show", hasYAxisType(data.axes, "y2"));
      chart.config("axis.y.show", hasYAxisType(data.axes, "y"));
      chart.config("grid.y.show", hasYAxisType(data.axes, "y"));
    };

    if (
      dataSeries &&
      !deepEqual(prevSeriesSummary.current, dataSeriesSummary)
    ) {
      let data = {
        xs: {},
        columns: [],
        types: {},
        unload: true,
        axes: {},
        regions: {},
      };

      if (dataSeries.length > 0) {
        if (haveMedium) {
          chart.config("tooltip.contents", makeTooltip);
        }

        chart.config("axis.x.show", true);

        //Add data to chart.
        for (const series of dataSeries) {
          if (series.type === bar()) {
            data.axes[series.name] = "y2";
            const filteredXs = filterTimeseries(series.columns.xs);
            data.columns.push(filteredXs);
          } else {
            data.axes[series.name] = "y";
            data.columns.push(series.columns.xs);
          }
          data.columns.push(series.columns.ys);
          data.xs[series.name] = series.xs;
          data.types[series.name] = series.type;

          if (series.type === "line") {
            data.regions[series.name] = calculateRegions(series);
          }
        }

        updateYAxes(data);
        
        data.done = () => {
          if (chart.unzoom) {
            chart.unzoom();
          }

          updateMinMax();
          document.body.style.cursor = "default";
        };
        document.body.style.cursor = "wait";
        chart.load(data);

        if (data.regions && Object.keys(data.regions).length > 0) {
          //Add doted regions to chart's config.
          chart.config("data.regions", data.regions);
        }
        
      } else {
        unloadChart();
      }
    } else if (dataSeries === undefined) {
      unloadChart();
    }

    if (dataSeries?.length > 0) {
      if (
        !deepEqual(prevGridConfig.current, {
          yMax,
          yMin,
          fieldCapacity,
          gridConfig,
        })
      ) {
        updateMinMax();
      }
    }

    prevGridConfig.current = {
      yMax,
      yMin,
      fieldCapacity,
      gridConfig,
    };
    prevSeriesSummary.current = dataSeriesSummary;
  }, [
    opts,
    dataSeries,
    yMaxMin,
    gridConfig,
    regionConfig,
    valueFormatterTooltip,
    dataSeriesSummary,
    yMax,
    yMin,
    fieldCapacity,
    irrigationThreshold,
    bindHoverLine,
    metricType.value,
    yAxisMax,
    yAxisMin,
    haveMedium,
    makeTooltip,
    yAxisConfig.stepSize,
  ]);

  return (
    <div
      id={`graph-${metricType?.value}`}
      style={{
        marginBottom: "-1.2rem",
        marginRight: "-1.2rem",
        marginLeft: "-1.6rem",
      }}
      ref={bbRef}
    />
  );
}
